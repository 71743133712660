import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isOfflineSyncEnabled } from './isOfflineSyncEnabled';
import { isOfflineSearchIndexingEnabled } from './isOfflineSearchIndexingEnabled';

export function isOfflineSearchEnabled(mailboxInfo: MailboxInfo) {
    return (
        isOfflineSyncEnabled(mailboxInfo) &&
        isOfflineSearchIndexingEnabled() &&
        isFeatureEnabled('sea-offlineSearch') &&
        !!Intl.Segmenter
    );
}
