import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type {
    ReadOnlyRibbonControlDefProps,
    RibbonControlDefinitionProps,
} from 'owa-mail-ribbon-utils';
import { getSecondarySettingDefaultsForMode, saveDensityOption } from 'owa-display-density-option';
import {
    lazyChangeNoteColor,
    lazyCreateNewNote,
    lazyDeleteNote,
    lazyViewEmailFromNote,
} from 'owa-notes-store';
import type DisplayDensityMode from 'owa-service/lib/contract/DisplayDensityMode';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import type { MenuOpenOnExecuteParameter } from 'owa-acui';
import type { OfficeOnlineApp } from 'owa-file/lib/types/OfficeOnlineApp';
import type { OwaDate } from 'owa-datetime';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type ConversationSortOrder from 'owa-service/lib/contract/ConversationSortOrder';
import type { ViewType } from 'owa-mail-organization-settings';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import type { MailboxInfo } from 'owa-client-types';
import checkAndCreateNewMessage from 'owa-mail-message-actions/lib/actions/checkAndCreateNewMessage';
import { getActionSourceFromCommandingViewMode } from 'owa-command-ribbon';
import { getMailMenuItemShouldShow } from 'owa-mail-filterable-menu-behavior';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import { getSelectedPublicFolderId } from 'owa-publicfolder-utils';
import { handleOfficeAppClick } from 'owa-mail-new-message-button/lib/utils/getOfficeAppsMenuOptions';
import initializeYammerPublisher from 'owa-mail-commands/lib/actions/initializeYammerPublisher';
import isFeatureEnabled from 'owa-feature-flags/lib/utils/isFeatureEnabled';
import { isYammerPublisherItemEnabled } from 'owa-mail-ribbon-utils/lib/isYammerPublisherItemEnabled';
import { launchCalendarFullCompose } from 'owa-calendar-forms-launch-handlers';
import { lazyInitializeConfigForYammer } from 'owa-yammer-prebootstrap';
import { lazyMountAndShowFullOptions } from 'owa-options-view';
import { lazyOnOpenCustomizer } from 'owa-mail-ribbon-customizer';
import { lazySaveReadingPaneOption } from 'owa-reading-pane-option';
import { toggleFolderPaneExpansion } from 'owa-mail-layout';
import { logUsage } from 'owa-analytics';
import onIgnoreStopIgnore from 'owa-mail-commands/lib/actions/onIgnoreStopIgnore';
import { onMessagePreviewToggle } from 'owa-message-preview-option/lib/actions/saveMessagePreviewOption';
import onNewYammerPost from 'owa-mail-commands/lib/actions/onNewYammerPost';
import onRestore from 'owa-mail-commands/lib/actions/onRestore';
import onPinUnpin from 'owa-mail-commands/lib/actions/onPinUnpin';
import onSnooze from 'owa-mail-commands/lib/actions/onSnooze';
import onSweep from 'owa-mail-commands/lib/actions/onSweep';
import onToggleRmsTemplate from 'owa-mail-commands/lib/actions/onToggleRmsTemplate';
import onUndo from 'owa-mail-commands/lib/actions/onUndo';
import {
    lazySaveConversationsOptionQuick,
    lazySaveStackedModeOptionsQuick,
} from 'owa-conversations-option';
import { lazySaveConversationsV2OptionQuick } from 'owa-conversationsv2-option/lib/ConversationsV2Option';
import { lazyRestoreAllItems } from 'owa-mail-triage-action';
import { getSelectedTableView } from 'owa-mail-list-store';
import { lazyLaunchRemindersPopup } from 'owa-header-app-notifications-helpers';
import type { StateChangeSource } from 'owa-left-pane-layout/lib/types/StateChangeSource';
import { isAllItemPartsExpanded } from 'owa-mail-reading-pane-store-conversation/lib/utils/isAllItemPartsExpanded';
import { lazyExpandCollapseAllItemParts } from 'owa-mail-reading-pane-store-conversation';
import toggleLightRP from 'owa-light-reading-pane-store/lib/actions/toggleLightRP';
import { lazyManualSyncMail } from 'owa-mail-manual-sync';
import { lazyToggleIsAlphabetizeFoldersEnabled } from 'owa-folders';
import {
    setBitAndUpdateAccountScopeUserSettingsAndService,
    type FolderPaneBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/folderPaneBitFlagsUtil';
import { lazyUpdateScenarioInResizeMode, ResizeHandleScenario } from 'owa-resize-handle';

export const onNewEvent = (props: RibbonControlDefinitionProps) => {
    launchCalendarFullCompose({
        fullComposeEntrySource: 'Mail_NewItems_EventButton',
        mailboxInfo: getRibbonMailboxInfo(props.mailboxInfo),
    });
};

export const onToggleFolderPane = (
    changeSource: StateChangeSource,
    newState: boolean | undefined
) => {
    toggleFolderPaneExpansion(changeSource, true /*persistOnServer*/, newState);
};

export const onFolderPaneResizeClicked = () => {
    lazyUpdateScenarioInResizeMode.importAndExecute(ResizeHandleScenario.FolderPane);
};

export const onNewMessage = (eventTimestamp?: number) => {
    checkAndCreateNewMessage(
        getActionSourceFromCommandingViewMode(),
        null /* targetId */,
        eventTimestamp
    );
};

export const onNewPost = (eventTimestamp?: number) => {
    checkAndCreateNewMessage(
        getActionSourceFromCommandingViewMode(),
        getSelectedPublicFolderId(),
        eventTimestamp
    );
};

export const onPinUnpinClicked = () => {
    onPinUnpin(getActionSourceFromCommandingViewMode());
};

export const onSweepClicked = (props: RibbonControlDefinitionProps) => {
    const mailboxInfo = getRibbonMailboxInfo(props.mailboxInfo);
    onSweep(mailboxInfo);
};

export const onSnoozeClicked = (date: OwaDate | undefined) => {
    onSnooze(date, getActionSourceFromCommandingViewMode());
};

export const onUndoClicked = () => {
    onUndo(getActionSourceFromCommandingViewMode());
};

export const onIgnoreStopIgnoreClicked = (props: ReadOnlyRibbonControlDefProps) => {
    const mailboxInfo = getRibbonMailboxInfo(props.mailboxInfo);
    onIgnoreStopIgnore(
        getActionSourceFromCommandingViewMode(),
        getMailMenuItemShouldShow(20, mailboxInfo)
    );
};

export const onNewEmailSplitButtonExecuted = (parameter: MenuOpenOnExecuteParameter) => {
    const isShadowRendering = !!parameter?.isShadowRendering == true;
    if (isShadowRendering) {
        return;
    }

    if (isFeatureEnabled('tri-newYammerPostButton')) {
        lazyInitializeConfigForYammer.importAndExecute();
    }

    if (isYammerPublisherItemEnabled()) {
        initializeYammerPublisher();
        logUsage('YammerPublisher_MenuItemSeen');
    }
};

export const onNewYammerPostClicked = () => {
    onNewYammerPost();
};

export const getOfficeAppClickHandler = (officeApp: OfficeOnlineApp, mailboxInfo: MailboxInfo) =>
    handleOfficeAppClick(officeApp, mailboxInfo);

export const onToggleRmsTemplateClicked = (
    composeId: string,
    retriever: (composeId: string) => RibbonMailComposeInfo | null,
    RmsTemplateId: string
) => {
    const editingInfo = retriever(composeId);
    if (editingInfo) {
        onToggleRmsTemplate(editingInfo.composeViewState, RmsTemplateId, editingInfo.targetWindow);
    }
};

export const onRibbonCustomizerClicked = (tabId: MailRibbonTabId, mailboxInfo: MailboxInfo) => {
    lazyOnOpenCustomizer.importAndExecute(tabId, mailboxInfo);
};

export const onNewNoteClicked = () => {
    lazyCreateNewNote.importAndExecute('NotesFolder');
};

export const onDeleteNoteClicked = () => {
    lazyDeleteNote.importAndExecute('NotesFolder');
};

export const onViewEmailFromNoteClicked = () => {
    lazyViewEmailFromNote.importAndExecute('NotesFolder');
};

export const onViewSettingsClicked = (props: ReadOnlyRibbonControlDefProps) => {
    const mailboxInfo = getRibbonMailboxInfo(props.mailboxInfo);
    lazyMountAndShowFullOptions.importAndExecute(
        'mail',
        'layout',
        undefined /* option */,
        undefined /* targetWindow */,
        mailboxInfo
    );
};

export const onConversationModeClicked = (listType: ReactListViewType) => {
    lazySaveConversationsOptionQuick.importAndExecute(listType);
};

export const onReadingPaneViewTypeClicked = (readingPaneViewType: ViewType) => {
    lazySaveConversationsV2OptionQuick.importAndExecute(undefined, readingPaneViewType);
};

export const onMessageListViewTypeClicked = (messageListViewType: ViewType) => {
    lazySaveConversationsV2OptionQuick.importAndExecute(messageListViewType, undefined);
};

export const onMessagePreviewClicked = (selected: boolean) => {
    onMessagePreviewToggle(selected);
};

export const onReadingPanePositionClicked = (readingPanePosition: number) => {
    lazySaveReadingPaneOption.importAndExecute(readingPanePosition);
};

export const onReadingPaneResizeClicked = () => {
    lazyUpdateScenarioInResizeMode.importAndExecute(ResizeHandleScenario.ReadingPane);
};

export const onReadingPaneOrderClicked = (order: ConversationSortOrder) => {
    lazySaveStackedModeOptionsQuick.importAndExecute(order);
};

export const onDensityClicked = (newDensity: DisplayDensityMode) => {
    //use density quick settings "recipe" instead of only changing font size, spacing
    const secondarySettings = getSecondarySettingDefaultsForMode(newDensity);
    saveDensityOption(newDensity, secondarySettings);
};

export const onChangeNoteColorClicked = (newColor: string) => {
    lazyChangeNoteColor.importAndExecute(newColor, 'NotesFolder');
};

export const onRestoreClicked = () => {
    onRestore(getActionSourceFromCommandingViewMode());
};

export const onRestoreAllClicked = () => {
    lazyRestoreAllItems.importAndExecute(
        getSelectedTableView(),
        getActionSourceFromCommandingViewMode()
    );
};

export const onRemindersClicked = () => {
    logUsage('RemindersButtonClicked-Mail');
    lazyLaunchRemindersPopup.importAndExecute(true /*isManuallyOpen*/);
};

export const onExpandCollapseConversationClicked = (itemId: string) => {
    const allItemsExpanded = isAllItemPartsExpanded(itemId);
    lazyExpandCollapseAllItemParts.importAndExecute(
        itemId,
        !allItemsExpanded /*shouldExpand*/,
        false /*isFromShortcut*/
    );
};

export const onSyncMailboxClicked = () => {
    lazyManualSyncMail.importAndExecute(getSelectedTableView());
};

export const onAlphabetizeFoldersClicked = async (mailboxInfo: MailboxInfo, isEnabled: boolean) => {
    await setBitAndUpdateAccountScopeUserSettingsAndService(isEnabled, 1, mailboxInfo);
    lazyToggleIsAlphabetizeFoldersEnabled.importAndExecute(mailboxInfo, isEnabled);
    if (isEnabled) {
        logUsage('FP_MoveFolderPos_AtoZSelected');
    } else {
        logUsage('FP_MoveFolderPos_CustomOrderSelected');
    }
};

export const onLightRPClicked = () => {
    toggleLightRP();
};
