import { LazyAction, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import { manualSyncFoldersSortChange } from 'owa-folders';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ManualSyncMail" */ './lazyIndex'),
    { name: 'ManualSyncMail' }
);

export const lazyManualSyncMail = new LazyAction(lazyModule, m => m.manualSyncMail);
export { getIsSyncingMailbox } from './selectors/getIsSyncingMailbox';

registerLazyOrchestrator(
    manualSyncFoldersSortChange,
    lazyModule,
    m => m.manualSyncFoldersSortChangeOrchestrator
);
