import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Action to get shared folders for a particular user.
 * @param mailboxInfo The mailbox from where the folders will be fetched.
 * @param actionSource Source from where this action is called.
 *
 */
export const manualSyncFoldersSortChange = action(
    'MANUAL_SYNC_FOLDERS_SORT_CHANGE',
    (mailboxInfo: MailboxInfo, actionSource: string) => {
        return {
            mailboxInfo,
            actionSource,
        };
    }
);
