import { isFeatureEnabled } from 'owa-feature-flags';

export function isOfflineSearchIndexingEnabled(): boolean {
    // Disable offline search indexing for devices with 4 or fewer cores
    const hardwareConcurrency = self.navigator?.hardwareConcurrency;
    const hasMoreThanFourCoresOrUnknown =
        typeof hardwareConcurrency !== 'number' || hardwareConcurrency > 4;
    return (
        isFeatureEnabled('sea-backgroundOfflineSearchIndexing-v3') && hasMoreThanFourCoresOrUnknown
    );
}
