import { OKRCategories } from './types/OKRCategories';
import { logCoreUsage } from 'owa-analytics';

type PrioritizeTelemetryEvent =
    | 'OpenInbox'
    | 'OpenSort'
    | 'OpenFilter'
    | 'EnabledChanged'
    | 'RulesCountChanged'
    | 'SummaryCardExpanded'
    | 'SummaryCardCollapsed'
    | 'SummaryCardDismissed'
    | 'SummaryCardContentCopied';

type PrioritizeTelemetryEntryPoint = 'MessageList' | 'Settings' | 'ReadingPane';

function getOKRCategoryForEvent(event: PrioritizeTelemetryEvent): OKRCategories {
    switch (event) {
        case 'OpenInbox':
        case 'OpenSort':
        case 'OpenFilter':
            return OKRCategories.Seen;
        case 'EnabledChanged':
        case 'RulesCountChanged':
        case 'SummaryCardExpanded':
        case 'SummaryCardCollapsed':
        case 'SummaryCardDismissed':
            return OKRCategories.Tried;
        case 'SummaryCardContentCopied':
            return OKRCategories.Kept;
        default:
            return OKRCategories.None;
    }
}

function getEntryPointForEvent(
    event: PrioritizeTelemetryEvent
): PrioritizeTelemetryEntryPoint | undefined {
    switch (event) {
        case 'OpenInbox':
        case 'OpenSort':
        case 'OpenFilter':
            return 'MessageList';
        case 'EnabledChanged':
        case 'RulesCountChanged':
            return 'Settings';
        case 'SummaryCardExpanded':
        case 'SummaryCardCollapsed':
        case 'SummaryCardDismissed':
        case 'SummaryCardContentCopied':
            return 'ReadingPane';
        default:
            return undefined;
    }
}

export function logPrioritizeTelemetryEvent(
    event: PrioritizeTelemetryEvent,
    customData: {
        [key: string]: any;
    } = {}
) {
    logCoreUsage('AIStandardTelemetry', {
        CopilotEventName: event,
        OKRCategory: getOKRCategoryForEvent(event),
        EntryPoint: getEntryPointForEvent(event),
        Action: 'Other',
        ...customData,
    });
}
